// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/HelveticaNeueMedium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/helvetica-neue-55);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'HelveticaNeueMedium';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');  
  font-weight: normal;
  font-style: normal;
}

/* background: var(--Gradient-Bg, linear-gradient(0deg, #FFFACE 13.46%, #CEFAFF 58.77%, #FFD9CE 88.88%)); */

:root {
  --shardeum-white: #FCFAEF;
  --shardeum-blue: #3042FB;
  --shardeum-orange: #FF4C0F;
  --shardeum-green: #A4FF00;
  
}

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'HelveticaNeueMedium', 'DM Sans' !important;
  
}

.Layout{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.Layout1{
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FCFAEF;
  margin-left: 250px;

}
a { color: inherit; text-decoration: none; } `, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,kCAAkC;EAClC,+DAA+D;EAC/D,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,2GAA2G;;AAE3G;EACE,yBAAyB;EACzB,wBAAwB;EACxB,0BAA0B;EAC1B,yBAAyB;;AAE3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,wDAAwD;;AAE1D;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;;AAEpB;AACA,IAAI,cAAc,EAAE,qBAAqB,EAAE","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');\n@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');\n\n@font-face {\n  font-family: 'HelveticaNeueMedium';\n  src: url('./assets/HelveticaNeueMedium.ttf') format('truetype');  \n  font-weight: normal;\n  font-style: normal;\n}\n\n/* background: var(--Gradient-Bg, linear-gradient(0deg, #FFFACE 13.46%, #CEFAFF 58.77%, #FFD9CE 88.88%)); */\n\n:root {\n  --shardeum-white: #FCFAEF;\n  --shardeum-blue: #3042FB;\n  --shardeum-orange: #FF4C0F;\n  --shardeum-green: #A4FF00;\n  \n}\n\n*{\n  margin: 0px;\n  padding: 0px;\n  box-sizing: border-box;\n  font-family: 'HelveticaNeueMedium', 'DM Sans' !important;\n  \n}\n\n.Layout{\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n}\n\n.Layout1{\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  background-color: #FCFAEF;\n  margin-left: 250px;\n\n}\na { color: inherit; text-decoration: none; } "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
