// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sync-button{
  padding: 5px;
  transition: box-shadow 0.5s ease-in-out, transform 0.2s ease-in-out !important;
}

.sync-button:hover{
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.15) !important;
  transform: scale(1.1) !important;
}

      `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,8EAA8E;AAChF;;AAEA;EACE,0DAA0D;EAC1D,gCAAgC;AAClC","sourcesContent":["\n.sync-button{\n  padding: 5px;\n  transition: box-shadow 0.5s ease-in-out, transform 0.2s ease-in-out !important;\n}\n\n.sync-button:hover{\n  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.15) !important;\n  transform: scale(1.1) !important;\n}\n\n      "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
