// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.sidenav{
    width: 250px;
    min-width: 250px;
    background-color: var(--shardeum-blue);
    height: 100vh;
    position: fixed;
}

.innersidenav{
    padding: 30px;
    padding-left: 30px;
    /* margin-top: 10px; */
}

.sidenavitems{
    color: white;
    display: flex;
    margin-top: 40px;
    font-size: 16px;
    cursor:pointer;

}



.sidenavicons{
    margin-right: 10px;
    font-size: 18px;
}

.sidenavtitle{
    padding: 20px;
    margin-top: 25px;
    color:  white;
    font-size: 28px;
    display: flex;
    align-items: center;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/SideNav/SideNav.css"],"names":[],"mappings":";;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,sCAAsC;IACtC,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,cAAc;;AAElB;;;;AAIA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":["\n\n.sidenav{\n    width: 250px;\n    min-width: 250px;\n    background-color: var(--shardeum-blue);\n    height: 100vh;\n    position: fixed;\n}\n\n.innersidenav{\n    padding: 30px;\n    padding-left: 30px;\n    /* margin-top: 10px; */\n}\n\n.sidenavitems{\n    color: white;\n    display: flex;\n    margin-top: 40px;\n    font-size: 16px;\n    cursor:pointer;\n\n}\n\n\n\n.sidenavicons{\n    margin-right: 10px;\n    font-size: 18px;\n}\n\n.sidenavtitle{\n    padding: 20px;\n    margin-top: 25px;\n    color:  white;\n    font-size: 28px;\n    display: flex;\n    align-items: center;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
