// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataCard{
    width: 25%;
    height: 240px;
    background-color: white;
    margin: 10px;
    border: 2px solid var(--shardeum-blue);
    border-radius: 20px;
    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.15);
    grid-area: datacard;
    min-width: 350px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    
}

.DataCard:hover {
    transform: scale(1.05); /* Scale up the card slightly on hover */
}

.datacardno{
    font-size: 16px;
    align-self: center;
    margin-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/Components/DataCard/DataCard.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,sCAAsC;IACtC,mBAAmB;IACnB,+CAA+C;IAC/C,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,sCAAsC;;AAE1C;;AAEA;IACI,sBAAsB,EAAE,wCAAwC;AACpE;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".DataCard{\n    width: 25%;\n    height: 240px;\n    background-color: white;\n    margin: 10px;\n    border: 2px solid var(--shardeum-blue);\n    border-radius: 20px;\n    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.15);\n    grid-area: datacard;\n    min-width: 350px;\n    padding: 30px;\n    display: flex;\n    flex-direction: column;\n    cursor: pointer;\n    transition: transform 0.2s ease-in-out;\n    \n}\n\n.DataCard:hover {\n    transform: scale(1.05); /* Scale up the card slightly on hover */\n}\n\n.datacardno{\n    font-size: 16px;\n    align-self: center;\n    margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
